.email-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 110vh;
  background-color: #f1f1f1;
  margin-top: 38px;
}

.email {
  max-width: 1200px;
  width: 100%;
  background-color: white;
  color: #333;
  margin: 20px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.email-header {
  background-color: #002855;
  color: white;
  display: flex;
  align-items: center;
  padding: 20px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.email-logo {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 10px;
}

.email-title {
  margin: 0;
  font-size: 24px;
  font-weight: bold;
}

.email-body {
  padding: 20px;
  line-height: 1.5;
  font-size: 18px;
}

.email-footer {
  background-color: #eee;
  padding: 20px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.email-footer p {
  margin: 0;
  font-size: 14px;
  font-style: italic;
  color: #999;
}

.email-images {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
}

.email-images img {
  width: 300px;
  height: auto;
  margin: 0 10px;
  opacity: 0.8;
  transition: all 0.2s ease-in-out;
  box-shadow: 0px 0px 80px 0px rgba(0,0,0,0.6);
}

.email-images img:nth-child(2) {
  width: 400px;
  opacity: 1;
  box-shadow: 0px 0px 80px 0px rgba(0,0,0,0.6);
}

.email-images img:nth-child(1) {
  transform: translateX(40px) scale(0.8);
}

.email-images img:nth-child(3) {
  transform: translateX(-40px) scale(0.8);
}